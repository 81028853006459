export enum ControlEnum {
  fulfill = 1,
  fails = 2,
  notApply = 3,
}

export enum SemaphoreControlEnum {
  red = 1,
  yellow = 2,
  green = 3,
  notApply = -1,
}

export enum Importances {
  low = 1,
  half = 2,
  high = 3,
}

export enum Roles {
  superAdmin = 1,
  admin = 2,
  auditor = 3,
  client = 4,
}

export enum AuditTypes {
  company = 1,
  machine = 2,
}

export enum MachineTableFieldTypes {
  empty = 1,
  header = 2,
  recommended = 3,
  value = 4,
}
