<div class="confirm-dialog">
  <p class="title">
    {{ message }}
  </p>
  <div>
    <div class="body-content">
      <p>{{ body }}</p>
    </div>
    <div class="buttons-div">
      <button mat-stroked-button color="primary" (click)="onCancel()">
        Cancelar
      </button>
      <button mat-raised-button color="primary" (click)="onConfirm()">
        Aceptar
      </button>
    </div>
  </div>
</div>
