import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Company } from '../models/company.model';
import { Auth, User } from '../models/user.model';
import { BaseService } from './base.service';

import { MatDialog } from '@angular/material/dialog';
import { Roles } from '../enums/index.enum';
import { UserLogged } from '../models/user-logged.model';
import { ConfirmDialogComponent } from '../modules/components/confirm-dialog/confirm-dialog.component';
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private database: DatabaseService,
    private dialog: MatDialog,
  ) {
    super();
    this.setAuth = this.setAuth.bind(this);
  }

  auth(): boolean {
    return localStorage.getItem('token') ? true : false;
  }

  get(): User {
    return {
      firstName: localStorage.getItem('firstName'),
      lastName: localStorage.getItem('lastName'),
      username: localStorage.getItem('username'),
      email: localStorage.getItem('email'),
      id: Number(localStorage.getItem('id')),
      role: Number(localStorage.getItem('role')),
    } as User;
  }

  getCompany(): Company {
    return JSON.parse(localStorage.getItem('selectedCompany')) as Company;
  }

  signIn(data: any): Observable<any> {
    return this.http
      .post<Auth>(`${this.url}/auth/front/sign-in`, data)
      .pipe(tap(this.setAuth));
  }

  private setAuth({
    token,
    user: { id, role, email, selectedCompany, firstName, lastName },
  }: Auth) {
    localStorage.setItem('token', token);
    localStorage.setItem('id', String(id));
    localStorage.setItem('role', String(role));
    localStorage.setItem('email', email);
    localStorage.setItem('firstName', firstName);
    localStorage.setItem('lastName', lastName);
    if (role !== Roles.superAdmin) {
      this.setSelectedCompany(selectedCompany);
    } else {
      localStorage.removeItem('selectedCompany');
    }
  }

  getToken(): string | null {
    return this.token();
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['auth/login']);
  }

  setSelectedCompany(selectedCompany: Company) {
    localStorage.setItem('selectedCompany', JSON.stringify(selectedCompany));
    localStorage.setItem('companyColor', selectedCompany.primaryColor);
    return this.http
      .post<{ token: string }>(
        `${this.url}/auth/company-sign-in`,
        {
          companyId: selectedCompany.id,
        },
        this.options(),
      )
      .pipe(tap(this.setCompanyToken));
  }

  private setCompanyToken({ token }: { token: string }) {
    localStorage.setItem('token', token);
  }

  public async checkUserLogged(user: Partial<User>) {
    const { id, role } = user;
    const [userLogged] = await this.database.list('userLogged');
    const aL = await this.database.list('audits');

    // There is a record in the UserLogged table but it is different than the user loggin in
    // and table audits has records
    if (userLogged && userLogged.id != id && aL.length > 0) {
      const modal = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message:
            'Existen auditorías en este dispositivo pertenecientes a otro usuario. ¿Desea eliminarlas? ',
          body: 'De lo contrario vuelva a hacer login con el usuario propietario de las mismas.',
        },
        autoFocus: false,
      });

      return new Promise((resolve) => {
        modal.afterClosed().subscribe((result) => {
          if (result.confirmed) {
            //delete caché
            this.database.clearAll();
            //save new user as userLogged
            this.database.clear('userLogged');
            this.database.update('userLogged', {
              id,
              role,
            });
            resolve(true);
          } else {
            //logout
            this.logout();
            resolve(false);
          }
        });
      });
    } else if ((userLogged && userLogged.id != id) || !userLogged) {
      this.database.clear('userLogged');
      this.database.update('userLogged', { id, role });
      return true;
    }
    return true;
  }

  public async checkCompanyLogged(user: Partial<User>) {
    const { id, role } = user;
    const [userLogged] = (await this.database.list(
      'userLogged',
    )) as UserLogged[];
    const aL = await this.database.list('audits');

    // There is a record in the UserLogged table but the company is different than the one selected
    // and table audits has records
    if (userLogged && userLogged.companyId != id && aL.length > 0) {
      const modal = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message:
            'Existen auditorías en este dispositivo pertenecientes a otra compañía. ¿Desea eliminarlas? ',
          body: 'De lo contrario seleccione la compañía propietaria de las mismas.',
        },
        autoFocus: false,
      });

      return new Promise((resolve) => {
        modal.afterClosed().subscribe((result) => {
          if (result.confirmed) {
            //delete caché
            this.database.clearAll();
            //save new user as userLogged
            this.database.clear('userLogged');
            this.database.update('userLogged', {
              id: userLogged.id,
              companyId: id,
              role,
            });
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    } else if ((userLogged && userLogged.companyId != id) || !userLogged) {
      this.database.clear('userLogged');
      this.database.update('userLogged', {
        id: userLogged.id,
        companyId: id,
        role,
      });
      return true;
    }
    return true;
  }
}
