import { Injectable } from '@angular/core';

import { Router, CanActivate } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private service: AuthService,
  ) {}

  canActivate() {
    const token = this.service.auth();
    if (token) {
      return true;
    } else {
      this.router.navigate(['auth']);
      return false;
    }
  }
}
