import { Injectable } from '@angular/core';
import { openDB } from 'idb';
import { Audit } from '../models/audit.model';
import { Photo } from '../models/photo.model';
import { DiscoveryTypes } from '../models/discovery-types.model';
import { UserLogged } from '../models/user-logged.model';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  private database: string = 'audits';
  private version: number = 3;
  private tables: string[] = [
    'audits',
    'photos',
    'discoveryTypes',
    'userLogged',
  ];

  constructor() {
    this.open();
  }

  private async open() {
    await openDB(this.database, this.version, {
      upgrade(db) {
        const audits = db.createObjectStore('audits', { keyPath: 'id' });
        audits.createIndex('id', 'id', { unique: true });
        const photos = db.createObjectStore('photos', { autoIncrement: true });
        photos.createIndex('auditId', 'auditId', { unique: false });
        photos.createIndex('controlId', 'controlId', { unique: false });
        const discoveryTypes = db.createObjectStore('discoveryTypes', {
          keyPath: 'id',
        });
        discoveryTypes.createIndex('id', 'id', { unique: true });
        const userLogged = db.createObjectStore('userLogged', {
          keyPath: 'id',
        });
        userLogged.createIndex('userId', 'userId', { unique: false });
      },
    });
  }

  async list(
    store: string,
  ): Promise<Audit[] | Photo[] | DiscoveryTypes[] | UserLogged[]> {
    const database = await openDB(this.database);
    return database.getAll(store);
  }

  async entries(store: string): Promise<Audit[] | Photo[]> {
    const database = await openDB(this.database);
    const keys = await database.getAllKeys(store);
    const values = await database.getAll(store);
    return values.map((value: any, index: number) => {
      return {
        id: keys[index],
        ...value,
      };
    });
  }

  async listBy(
    store: string,
    index: string,
    indexValue: string | number | string[],
  ): Promise<Audit[] | Photo[]> {
    const database = await openDB(this.database);
    return await database.getAllFromIndex(store, index, indexValue);
  }

  async listPhotosByControlId(
    store: string,
    index: string,
    indexValue: string | number | string[],
  ): Promise<Photo[]> {
    const database = await openDB(this.database);
    return await database.getAllFromIndex(store, index, indexValue);
  }

  async get(store: string, key: number): Promise<Audit | Photo | UserLogged> {
    const database = await openDB(this.database);
    return database.get(store, key);
  }

  async getAuditById(key: number): Promise<Audit> {
    const database = await openDB(this.database);
    return database.get('audits', key);
  }

  async update(
    store: string,
    objects: Audit | Photo | DiscoveryTypes | UserLogged,
  ): Promise<void> {
    const database = await openDB(this.database);

    const transaction = database.transaction(store, 'readwrite');

    if (Array.isArray(objects)) {
      const auditControlStore = transaction.objectStore(store);
      for (const auditControl of objects) {
        auditControlStore.put(auditControl);
      }
    } else {
      transaction.objectStore(store).put(objects);
    }

    await transaction.done;
  }

  async remove(store: string, key: number): Promise<void> {
    const database = await openDB(this.database);
    return database.delete(store, key);
  }

  async clear(store: string): Promise<void> {
    const database = await openDB(this.database);
    return database.clear(store);
  }

  async clearAll(): Promise<void> {
    const database = await openDB(this.database);
    for (const table of this.tables) {
      if (table != 'userLogged') {
        await database.clear(table);
      }
    }
  }

  async count(store: string): Promise<number> {
    const database = await openDB(this.database);
    const count = await database.count(store);
    return count;
  }
}
